import { isValid, parse, differenceInYears } from "date-fns";
import { enGB, enUS, ja } from "date-fns/locale";

type SpecificDayMonthYearDateFormatType = {
  [key: string]: string;
};

export const DayMonthYearFormatCountryList = [
  "fr-fr",
  "de-de",
  "es-es",
  "es-mx",
  "it-it",
  "pl-pl",
  "pt-br",
  "ru-ru",
  "tr-tr",
];
export const YearMonthDayFormatCountryList = ["ja-jp", "ko-kr", "zh-tw"];
export const SpecificDayMonthYearDateFormat: SpecificDayMonthYearDateFormatType = {
  "fr-fr": "JJ/MM/AAAA",
  "es-es": "DD/MM/AAAA",
  "es-mx": "DD/MM/AAAA",
  "it-it": "GG/MM/AAAA",
  "pl-pl": "DD/MM/RRRR",
  "pt-br": "DD/MM/AAAA",
  "ru-ru": "ДД/ММ/ГГГГ",
  "tr-tr": "GG/AA/YYYY",
};

// validate date of birth field
export const isBirthdayValid = (formattedBirthday: string, locale: string) => {
  const validFormatRegex = YearMonthDayFormatCountryList.includes(locale)
    ? /\d{4}\/\d{2}\/\d{2}/
    : /\d{2}\/\d{2}\/\d{4}/;

  if (!formattedBirthday || !validFormatRegex.test(formattedBirthday)) {
    return false;
  }

  // NOTE: only `ja` in the `date-fns` library use as YYYY/MM/DD format, so used `ja` for YearMonthDayFormat
  // enUS: MM/DD/YYYY
  // enGB: DD/MM/YYYY
  let dateLocaleFormat = YearMonthDayFormatCountryList.includes(locale) ? ja : locale === "en-us" ? enUS : enGB;

  let parsedDate = parse(formattedBirthday, "P", new Date(), {
    locale: dateLocaleFormat,
  });

  // is date valid?
  if (!isValid(parsedDate)) {
    return false;
  }

  // is the user at least 13 years old?
  let diff = differenceInYears(new Date(), parsedDate);

  if (!diff || diff < 13) {
    return false;
  }

  return true;
};
