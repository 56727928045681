import React, { useState, useEffect } from "react";
import Error from "../Error";
import { isBirthdayValid } from "../../utils/validateBirthday";
import { DayMonthYearFormatCountryList, YearMonthDayFormatCountryList } from "../../utils/validateBirthday";

export interface BirthdayProps {
  errorMessage: string;
  label: string;
  format: string;
  lang: string;
  onChange: (birthday: string) => void;
  onError: (error: boolean) => void;
}

export const BirthdayField: React.FC<BirthdayProps> = ({ errorMessage, label, format, lang, onChange, onError }) => {
  let locale = lang;

  const [initialState, setInitialState] = useState(true);
  const [errorText, setErrorText] = useState("");
  const [formattedBirthday, setFormattedBirthday] = useState("");
  const [isFocused, setFocus] = useState(false);

  useEffect(() => {
    if (!initialState) {
      // validate birthday
      if (isBirthdayValid(formattedBirthday, locale)) {
        onChange(formattedBirthday);
        setErrorText("");
        onError(false);
        return;
      }
      setErrorText(errorMessage);
      onError(true);
      setFocus(false);
    }
  }, [formattedBirthday, errorMessage, onError, onChange]);

  const formatBirthday = () => {
    let cleanedBirthday = formattedBirthday.trim().replace(/\//gi, "");
    let slashedBirthday = "";

    // format birthday
    if (YearMonthDayFormatCountryList.includes(locale)) {
      // XXXX/XX/XX
      slashedBirthday =
        cleanedBirthday.slice(0, 4) + "/" + cleanedBirthday.slice(4, 6) + "/" + cleanedBirthday.slice(6, 8);
    } else {
      // to XX/XX/XXXX
      slashedBirthday =
        cleanedBirthday.slice(0, 2) + "/" + cleanedBirthday.slice(2, 4) + "/" + cleanedBirthday.slice(4, 8);
    }

    setFormattedBirthday(slashedBirthday);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInitialState(false);
    setFocus(true);
    setFormattedBirthday(event.target.value);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setInitialState(false);
    // set error message if empty
    if (!formattedBirthday) {
      setErrorText(errorMessage);
      return;
    }
    // format birthday
    formatBirthday();
  };

  const handleFocus = (event: React.FocusEvent<HTMLLabelElement | HTMLInputElement>) => {
    event.preventDefault();
    setInitialState(false);
    setFocus(true);
    setErrorText("");
  };

  return (
    <div className="birthday-field">
      <label htmlFor="birthdate" className={isFocused || formattedBirthday ? "floating" : ""}>
        {label}
      </label>
      <input
        key="birthdate-input"
        type="text"
        id="birthdate"
        name="birthdate"
        value={formattedBirthday}
        placeholder={!isFocused ? "" : format}
        onFocus={(event) => handleFocus(event)}
        onChange={(event) => handleChange(event)}
        onBlur={(event) => handleBlur(event)}
        className={errorText ? "error" : ""}
      />
      {errorText && <Error error={errorText} />}
    </div>
  );
};

export default BirthdayField;
