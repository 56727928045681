import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import bannerImage from "../../assets/images/banner-image.jpg";

export const StyledDiv = styled.div`
  background: ${colors.orange};
  color: ${colors.white};
  position: relative;
  font-family: "ProximaNova-Bold";
  font-size: 0.875rem;
  margin: 0;
  padding: 0.938rem 1rem 1rem 1rem;
  text-align: center;
  text-transform: uppercase;

  span {
    position: relative;
    z-index: 5;
  }

  a {
    color: ${colors.lime};
  }

  &.link {
    cursor: pointer;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    background-color: ${colors.black};
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 0;
  }

  &:after {
    width: 40%;
    right: 40%;
    background-image: url(${bannerImage});
    background-repeat: no-repeat;
    background-position: 60% 52.5%;
    background-size: 100%;
    box-shadow: inset 50px 0px 50px black;
  }

  &:before {
    width: 65%;
    right: 70%;
    background: ${colors.black};
  }

  &:hover {
    &:before,
    &:after {
      opacity: 0.3;
    }

    &:after {
      right: 0%;
    }

    &:before {
      right: 40%;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    a {
      display: block;
    }
  }
`;
