import React from "react"
import styled from "styled-components";
import gsap from "gsap";

interface Props {
	slideImg: any,
	activeSlide: number,
	slideIndex: number
}

interface State {
	visible: boolean
}

 export default class FullSlide extends React.Component<Props, State> {
	private selfRef: React.RefObject<HTMLElement>;
	private prevSlide: number = -1;
	private showing: boolean = false;

	constructor(props:Props) {
		super(props);
		this.selfRef = React.createRef();
		this.state = {
			visible: false
		}
	}

	// Animates slide in
	show(direction: number) {
		if (this.showing) return;
		this.showing = true;

		const q = gsap.utils.selector(this.selfRef);
		gsap.fromTo(
			q(".imgInner"),
			{x: `${direction * 20}%`},
			{x: "0%", ease: "power2.out", duration: 1.0}
		);
		gsap.fromTo(
			this.selfRef.current,
			{maskImage: `linear-gradient(${direction * -45}deg, #fff -10%, transparent 0%)`},
			{maskImage: `linear-gradient(${direction * -45}deg, #fff 100%, transparent 110%)`, ease: "power4.inOut", duration: 1.0}
		);
		this.setState({visible: true});
	}

	// Animates slide out
	hide(direction: number) {
		if (!this.showing) return;
		this.showing = false;

		const q = gsap.utils.selector(this.selfRef);
		gsap.to(q(".imgInner"), {
			x: `${direction * -20}%`,
			ease: "power2.in",
			duration: 1.0,
			onComplete: () => {
				this.setState({visible: false});
			}
		});
		gsap.fromTo(
			this.selfRef.current,
			{maskImage: `linear-gradient(${direction * -45}deg, transparent -10%, #fff 0%)`},
			{maskImage: `linear-gradient(${direction * -45}deg, transparent 100%, #fff 110%)`, ease: "power4.inOut", duration: 1.0}
		);
	}

	// Determine whether to show or hide
	componentWillReceiveProps(newProps: any) {
		const newSlide = newProps.activeSlide;
		const direction = (this.prevSlide < newSlide) ? 1 : -1;
		this.prevSlide = newSlide;

		if (newSlide === this.props.slideIndex && !this.showing) {
			this.show(direction);
		} else if (newSlide !== this.props.slideIndex && this.showing) {
			this.hide(direction);
		}
	}

	render() {
		const activeClass = (this.state.visible) ? "active" : "";
		const imgURL = this.props.slideImg;

		return(
			<ImgOuter className={`${activeClass}`} ref={ this.selfRef }>
				<div className="imgInner" style={{ backgroundImage: `url("${imgURL}")` }} />
			</ImgOuter>
		)
	}
 }

const ImgOuter = styled.div<{ref: any}>`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	overflow: hidden;
	visibility: hidden;
	.imgInner {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}

	&.active {
		visibility: visible;
	}
`;