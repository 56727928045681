import styled from "styled-components";
import { colors, breakpoints, zIndices } from "../../styles/variables";
import { device } from "../Layout/theme";

export const ChampionSection = styled.section`
  position: relative;
  padding: clamp(60px, 10vw, 140px) 0;
  background-size: cover;
  background-color: ${colors.medGreen};
  overflow: hidden;

  .contentDiv {
    grid-column: 1 / span 12;
  }
  .titleBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 64px;

    .text-header {
      color: ${colors.lightGreen};
      position: relative;
    }
  }
  .champColumns {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 20px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 12vw 0;

    .contentDiv {
      grid-column: 2 / span 6;
    }
    .champColumns {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;

      & > div:last-child {
        grid-column: span 2;
        width: calc(50% - 15px);
        justify-self: center;
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 64px 0;

    .contentDiv {
      grid-column: 1 / span 2;
    }
    .titleBox {
      margin-bottom: 32px;
    }
    .fluteImage {
      width: 100px;
    }
    .champColumns {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0; // flex-gap not well supported in browser

      & > div {
        grid-column: span 1;
        margin-bottom: 32px;

        &:last-child {
          width: 80vw;
          max-width: 300px;
        }
      }
    }
  }
`;

export const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  z-index: 0;
  pointer-events: none;
  overflow: hidden;
  background: ${colors.medGreen};

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    background: #496f68;
    opacity: 0.2;
    backdrop-filter: blur(10px);
    z-index: 1;
  }

  .desktop {
    display: none;
  }
  .top-bg-leaves,
  .top-bg-mask {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .top-bg-leaves {
    top: -30px;
    left: 0;
    z-index: 2;
  }
  .top-bg-mask {
    right: 0;
  }

  .bottom-bg-leaves,
  .bottom-bg-mask {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .bottom-bg-leaves {
    right: -15%;
    width: 120%;
    z-index: 2;
  }
  .bottom-bg-mask {
    left: 0;
  }

  @media ${device.tablet} {
    .top-bg-leaves {
      top: 0px;
      width: 60%;
    }
    .bottom-bg-leaves {
      width: 90%;
      right: 0px;
    }
  }

  @media ${device.laptopM} {
    background-size: cover;
    background-position: center center;

    .mobile {
      display: none;
    }
    /* &::before {
      opacity: 0;
    } */

    .desktop {
      display: block;
    }
    .desktop-bottom-bg-leaves,
    .desktop-top-bg-leaves {
      position: absolute;
      z-index: 2;
    }
    .desktop-top-bg-leaves {
      top: 0;
      left: 0;
      width: 40%;
      max-width: 700px;
    }
    .desktop-bottom-bg-leaves {
      bottom: 0;
      right: 0;
      width: 40%;
      max-width: 600px;
    }
    .desktop-left-bg-mask {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
    .desktop-right-bg-mask {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }
  }
`;
