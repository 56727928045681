import React from "react"
import { Slide } from "./styles";
import gsap from "gsap";

interface Props {
	slideData: any,
	activeSlide: number,
	slideIndex: number
}

interface State {
	visible: boolean
}

 export default class FeatureSlide extends React.Component<Props, State> {
 	private selfRef: React.RefObject<HTMLElement>;
 	private videoRef: React.RefObject<HTMLVideoElement>;
 	private prevSlide: number = -1;
 	private showing: boolean = false;

	constructor(props:Props) {
		super(props);
		this.selfRef = React.createRef();
		this.videoRef = React.createRef();
		this.state = {
			visible: false
		}
	}

	// Animates slide in
	show(direction: number) {
		if (this.showing) return;
		this.showing = true;

		const q = gsap.utils.selector(this.selfRef);
		gsap.fromTo(
			[q(".txt1"), q(".txt2"), q(".txt3")],
			{opacity: 0, y: "30%"},
			{opacity: 1, y: "0%", stagger: 0.1}
		);
		gsap.fromTo(
			q(".imageBox"),
			{maskImage: `linear-gradient(${direction * -45}deg, #fff 0%, transparent 0%)`},
			{maskImage: `linear-gradient(${direction * -45}deg, #fff 100%, transparent 100%)`, ease: "power4.inOut"}
		);
		gsap.fromTo(q(".slideImage"), {x: `${direction * 10}%`}, {x: "0%", ease: "power2.inOut"});
		this.setState({visible: true});

		// Plays video if needed
		if (this.videoRef.current) {
			this.videoRef.current.play();
		}
	}

	// Animates slide out
	hide(direction: number) {
		if (!this.showing) return;
		this.showing = false;

		const q = gsap.utils.selector(this.selfRef);
		gsap.to(q(".slideImage"), {x: `${direction * -10}%`, ease: "power2.inOut"});
		gsap.to(
			[q(".txt1"), q(".txt2"), q(".txt3")],
			{opacity: 0, y: "-30%", stagger: 0.1, onComplete: () => {
				this.setState({visible: false});
			}}
		);
		gsap.fromTo(
			q(".imageBox"),
			{maskImage: `linear-gradient(${direction * -45}deg, transparent 0%, #fff 0%)`},
			{maskImage: `linear-gradient(${direction * -45}deg, transparent 100%, #fff 100%)`, ease: "power4.inOut"}
		);

		// Pause video if needed
		if (this.videoRef.current) {
			this.videoRef.current.pause();
		}
	}

	// Determine whether to show or hide
	componentWillReceiveProps(newProps: any) {
		const newSlide = newProps.activeSlide;
		const direction = (this.prevSlide < newSlide) ? 1 : -1;
		this.prevSlide = newSlide;

		if (newSlide === this.props.slideIndex && !this.showing) {
			this.show(direction);
		} else if (newSlide !== this.props.slideIndex && this.showing) {
			this.hide(direction);
		}
	}

	renderVideoPlayer() {
		return (
			<div className="imageBox">
				<video className="slideImage vidPlayer" playsInline loop muted ref={ this.videoRef }>
					<source src={this.props.slideData.videoURL}></source>
				</video>
			</div>
		);
	}

	renderStaticImage() {
		return (
			<div className="imageBox">
				<div className="slideImage" style={{ backgroundImage: `url("${ this.props.slideData.img }")` }}></div>
			</div>
		);
	}

	render() {
		const activeClass = (this.state.visible) ? "active" : "";
		const padNumber = String(this.props.slideIndex + 1).padStart(2, '0');
		const s = this.props.slideData;

		return(
			<Slide className={`${activeClass}`} ref={ this.selfRef }>
				{s.videoURL ? this.renderVideoPlayer() : this.renderStaticImage()}
				<div className="copyBox">
					<div className="text-subheader txt1">{ padNumber }</div>
					<div className="text-subheader txt2">{ s.title }</div>
					<div className="text-paragraph txt3">{ s.desc }</div>
				</div>
			</Slide>
		)
	}
 }
