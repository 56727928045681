import * as React from "react";
import styled from "styled-components";
import { zIndices } from "../styles/variables";

interface Props {
  className?: string;
  lightningLines?: string;
}

const StyledContainer = styled.div`
  width: 100%;
`;

const ClippedContainer = styled.div<Props>`
  position: relative;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  z-index: ${zIndices.lightningClip};
  clip-path: polygon(
    0px 0px,
    100% 0px,
    100% calc(100% - 1vw),
    10.5% calc(100% - 13.5vw),
    11.8% calc(100% - 6.5vw),
    0px calc(100% - 8.5vw)
  );
  padding-bottom: 14vw;

  &.hero {
    min-height: 1920px;
    height: 1920px;
    max-height: 1920px;

    @media (max-width: 1600px) {
      height: 120vw;
      max-height: 2052px;
      min-height: 1640px;
    }

    @media (max-width: 1200px) {
      min-height: 1100px;
      height: 112vw;
      max-height: 1400px;
    }

    @media (max-width: 767px) {
      min-height: 830px;
      height: 120vh;
      max-height: 880px;
    }
  }
`;

const SVGLightningLines = styled.svg<Props>`
  pointer-events: none;
  display: block;
  position: absolute;
  left: 0;
  bottom: ${(p) => p.lightningLines};
  width: 100%;
  height: auto;
  z-index: ${zIndices.lightningClip};
  transform: translateY(-62.5%);
`;

export default class LeftLightningClippedContainer extends React.Component<Props> {
  render() {
    return (
      <StyledContainer>
        <ClippedContainer className={this.props.className}>{this.props.children}</ClippedContainer>
        <SVGLightningLines
          lightningLines={this.props.lightningLines}
          width="1440"
          height="382"
          viewBox="0 0 1440 382"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M-991 1L215.5 173.5L198 74.5L2305 373" stroke="#F9E5A9" />
          <path d="M-999 9L207.5 181.5L190 82.5L2297 381" stroke="#F9E5A9" />
        </SVGLightningLines>
      </StyledContainer>
    );
  }
}
