import React from "react";
import { graphql } from "gatsby";
import GridContainer from "../GridContainer";
import ChampionsCard from "./ChampionsCard";
import DecoLine from "../DecoLine";
import topLeavesBG from "../../assets/images/champion/mobile-bg-top-leaves.png";
import bottomLeavesBG from "../../assets/images/champion/mobile-bg-bottom-leaves.png";
import topMaskBG from "../../assets/images/champion/mobile-bg-top-mask.png";
import bottomMaskBG from "../../assets/images/champion/mobile-bg-bottom-mask.png";
import desktopTopLeavesBG from "../../assets/images/champion/desktop-bg-top-leaves.png";
import desktopBottomLeavesBG from "../../assets/images/champion/desktop-bg-bottom-leaves.png";
import desktopLeftMaskBG from "../../assets/images/champion/desktop-bg-left-mask.png";
import desktopRightMaskBG from "../../assets/images/champion/desktop-bg-right-mask.png";

import { ChampionSection, BackgroundWrapper } from "./styles";

export const fragment = graphql`
  fragment GameChampionsFragment on Contentstack_bandle_tale_home_page {
    gameChampions: game_champions {
      headline
      champions {
        headline
        thumbnail {
          url
        }
        universeLink: universe_link {
          href
          title
        }
      }
    }
  }
`;

export interface ChampionsData {
  headline: string;
  champions: [
    {
      headline: string;
      thumbnail: {
        url: string;
      };
      universeLink: {
        href: string;
        title: string;
      };
    },
  ];
}

interface Props {
  data: ChampionsData;
}

export default class Champions extends React.Component<Props> {
  render() {
    const { headline, champions } = this.props.data;

    return (
      <ChampionSection>
        {/* background ??? */}
        <BackgroundWrapper>
          {/* mobile */}
          <img src={topLeavesBG} className="top-bg-leaves mobile" />
          <img src={bottomLeavesBG} className="bottom-bg-leaves mobile" />
          <img src={topMaskBG} className="top-bg-mask mobile" />
          <img src={bottomMaskBG} className="bottom-bg-mask mobile" />
          {/* desktop */}
          <img src={desktopTopLeavesBG} className="desktop-top-bg-leaves desktop" />
          <img src={desktopBottomLeavesBG} className="desktop-bottom-bg-leaves desktop" />
          <img src={desktopLeftMaskBG} className="desktop-left-bg-mask desktop" />
          <img src={desktopRightMaskBG} className="desktop-right-bg-mask desktop" />
        </BackgroundWrapper>
        <GridContainer className="gridContainer">
          <div className="contentDiv">
            <div className="titleBox">
              <div className="text-header">{headline || "Champions"}</div>
            </div>
            <DecoLine position="top" />
            <div className="champColumns">
              {champions.map((champ, id) => (
                <ChampionsCard key={champ.headline} imageSrc={champ.thumbnail.url} linkHref={champ.universeLink.href}>
                  {champ.headline}
                </ChampionsCard>
              ))}
            </div>
            <DecoLine position="bottom" />
          </div>
        </GridContainer>
      </ChampionSection>
    );
  }
}
