import React from "react";
import { graphql } from "gatsby";
import { navigate } from "@reach/router";
import { ContentstackImg } from "@riotgames/wwpub-components";
import ButtonText from "../buttons/ButtonText";
import VideoPreview from "../VideoPreview";
import RightLightningClippedContainer from "../RightLightningClippedContainer";
import LeftLightningClippedContainer from "../LeftLightningClippedContainer";
import imgBackground from "../../images/hero/background.jpg";

// new assets
import imgTreeWrapper from "../../images/hero/tree-wrapper-2.png";
import imgMainChar from "../../images/hero/new-main-char.png";
import imgGlow from "../../images/hero/glow.png";

import { HeroSection } from "./styles";

export const fragment = graphql`
  fragment HeroMarqueeFragment on Contentstack_bandle_tale_home_page {
    heroMarquee: home_page_hero_marquee {
      gameLogo: game_logo {
        url
      }
      videoLink: video_link {
        title
        href
      }
      inlineVideoFile: inline_video_file {
        url
      }
      videoThumb: video_thumb {
        url
      }
      platforms {
        title
        platformLogo: platform_logo {
          svg {
            content
          }
        }
      }
      videoDescriptor: video_descriptor
      flavorCopy: flavor_copy
      buyPreorderCta: available_now_cta {
        href
        title
      }
    }
  }
`;

export interface HeroData {
  gameLogo: {
    url: string;
  };
  videoLink: {
    title: string;
    href: string;
  };
  inlineVideoFile: {
    url: string;
  };
  videoThumb: {
    url: string;
  };
  platforms: [
    {
      title: string;
      platformLogo: {
        svg: {
          content: string;
        };
      };
    },
  ];
  videoDescriptor: string;
  flavorCopy: string;
  preOrderText: string;
  buyPreorderCta: {
    title: string;
    href: string;
  };
}

interface Props {
  data: HeroData;
  videoOpen: Function;
}

interface Vec2 {
  x: number;
  y: number;
}

// Tween to target using Zeno's Paradox
function zTween(_val: number, _target: number, _ratio: number): number {
  return Math.abs(_target - _val) < 1e-5 ? _target : _val + (_target - _val) * Math.min(_ratio, 1.0);
}

export default class Hero extends React.Component<Props> {
  private charLeft: React.RefObject<HTMLImageElement>;
  private charLeftMobile: React.RefObject<HTMLImageElement>;
  private charSwoosh: React.RefObject<HTMLImageElement>;
  private charRight: React.RefObject<HTMLImageElement>;
  private charSwirl: React.RefObject<HTMLImageElement>;
  // new
  private charMain: React.RefObject<HTMLImageElement>;
  private treeWrapper: React.RefObject<HTMLImageElement>;
  private glow: React.RefObject<HTMLImageElement>;
  private background: React.RefObject<HTMLDivElement>;
  private targetPos: Vec2;
  private actualPos: Vec2;
  private raf: number = 0;

  constructor(props: Props) {
    super(props);
    this.charLeft = React.createRef();
    this.charLeftMobile = React.createRef();
    this.charSwoosh = React.createRef();
    this.charRight = React.createRef();
    this.charSwirl = React.createRef();

    // new
    this.charMain = React.createRef();
    this.treeWrapper = React.createRef();
    this.background = React.createRef();
    this.glow = React.createRef();
    // ? see if animate bg is better
    this.background = React.createRef();
    // this.glow = React.createRef();

    this.targetPos = { x: 0, y: 0 };
    this.actualPos = { x: 0, y: 0 };
  }

  scrollToNewsletter = () => {
    document.getElementById("Newsletter")?.scrollIntoView({ inline: "center" });
  };

  onMouseMove = (event: MouseEvent | TouchEvent) => {
    let x = 0,
      y = 0;
    if ("touches" in event && event.touches.length) {
      x = event.touches[0].pageX;
      y = event.touches[0].pageY;
    } else if ("clientX" in event) {
      x = event.clientX;
      y = event.clientY;
    }
    this.targetPos.x = (x / window.innerWidth) * -2.0 + 1.0;
    this.targetPos.y = (y / window.innerHeight) * -2.0 + 1.0;
  };

  translate(ref: React.RefObject<HTMLImageElement> | React.RefObject<HTMLDivElement>, multiplier: number) {
    if (ref.current) {
      ref.current.style.transform = `translate(${this.actualPos.x * multiplier}vw, ${this.actualPos.y * multiplier}vw)`;
    }
  }

  onUpdate = () => {
    // Smooth tweening towards targetPos
    this.actualPos.x = zTween(this.actualPos.x, this.targetPos.x, 0.1);
    this.actualPos.y = zTween(this.actualPos.y, this.targetPos.y, 0.1);
    this.raf = window.requestAnimationFrame(this.onUpdate);

    // Cancel if tween complete
    if (this.actualPos.x === this.targetPos.x && this.actualPos.y === this.targetPos.y) return;

    this.translate(this.charMain, -1);
    this.translate(this.background, 0.7);
    this.translate(this.treeWrapper, 1.2);
    this.translate(this.glow, 0.8);
  };

  componentDidMount() {
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("touchmove", this.onMouseMove);
    this.raf = window.requestAnimationFrame(this.onUpdate);
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("touchmove", this.onMouseMove);
    window.cancelAnimationFrame(this.raf);
  }

  render() {
    const {
      gameLogo,
      videoLink,
      inlineVideoFile,
      videoThumb,
      platforms,
      videoDescriptor,
      flavorCopy,
      preOrderText,
      buyPreorderCta,
    } = this.props.data;

    return (
      <HeroSection>
        <LeftLightningClippedContainer className="hero">
          {/* parallax components */}
          <div
            className="backgroundImg"
            ref={this.background}
            style={{ backgroundImage: `url("${imgBackground}")` }}
          ></div>
          <img className="char treeWrapper" ref={this.treeWrapper} src={imgTreeWrapper} />
          <img className="char mainChar" ref={this.charMain} src={imgMainChar} />
          <img className="char glow" ref={this.glow} src={imgGlow} />

          <div className="centerCol">
            <ContentstackImg className="mainLogo" image={{ url: gameLogo.url }} />
            <VideoPreview
              videoOpen={this.props.videoOpen}
              thumbnailImage={videoThumb.url}
              videoURL={inlineVideoFile.url}
              textCaption={videoLink.title}
            ></VideoPreview>
            <div className="infoGroup">
              <div className="text-caption available">{videoDescriptor}</div>
              <div className="platforms">
                {platforms.map((platform) => (
                  <span
                    key={platform.title}
                    dangerouslySetInnerHTML={{ __html: platform.platformLogo.svg.content }}
                  ></span>
                ))}
              </div>
              <p className="text-paragraph">{flavorCopy}</p>
              <ButtonText colorTheme="green" callback={() => navigate(buyPreorderCta.href)}>
                {buyPreorderCta.title}
              </ButtonText>
            </div>
          </div>
        </LeftLightningClippedContainer>
      </HeroSection>
    );
  }
}
