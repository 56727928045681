import React from "react"
import { graphql } from "gatsby";
import GridContainer from "../GridContainer";
import ButtonUI from "../buttons/ButtonUI";
import { clamp } from "../../helpers/utils";

import { HorizontalGallerySection } from "./styles"

export const fragment = graphql`
	fragment GamesCarouselFragment on Contentstack_bandle_tale_home_page {
		gamesCarousel: games_carousel {
			headline
			reference {
				title
				websiteUrl: website_url
				buyNowUrl: buy_now_url
				portraitThumbnail: portrait_thumbnail {
					url
				}
				landscapeThumbnail: landscape_thumbnail {
					url
				}
				logo {
					url
				}
			}
		}
	}
`

export interface HorizontalGalleryData {
	headline: string
	reference: [{
		title: string
		websiteUrl: string
		buyNowUrl: string
		portraitThumbnail?: {
			url: string
		}
		landscapeThumbnail?: {
			url: string
		}
		logo?: {
			url: string
		}
	}]
}

interface Props {
	data: HorizontalGalleryData;
}

interface State {
	slide: number
};

export default class HorizontalGallery extends React.Component <Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {slide: 0};
	}

	arrowClick = (direction: -1 | 1) => {
		let newSlide = clamp(this.state.slide + direction, 0, this.props.data.reference.length - 1);
		this.setState({slide: newSlide});
	}

	slideClick = (slideURL: string) => {
		window.open(slideURL, "_new");
	}

	renderSlides(): JSX.Element[] {
		const slideData = this.props.data.reference.map(game => ({
			img: game.portraitThumbnail?.url,
			title: game.title,
			url: game.websiteUrl,
		}));

		const xDisp = `calc(${this.state.slide * -100}% + ${this.state.slide * -24}px)`;
		const allSlides = slideData.map( (slide: any, index: number ) => {
			return (
				<div className="riotSlide"
					style={{ transform: `translateX(${ xDisp })` }}
					onClick={() => this.slideClick(slide.url)}
					key={`slide-${index}`}
				>
					<div className="slideImage" style={{ backgroundImage: `url("${ slide.img }")` }}></div>
					<div className="bottomBox">
						<div className="text-subheader">{ slide.title }</div>
					</div>
				</div>
			);
		});

		return allSlides;
	}

	render() {
		const { headline, reference } = this.props.data

		const disableL = (this.state.slide <= 0);
		const disableR = (this.state.slide >= reference.length - 1);

		return (
			<HorizontalGallerySection>
				<GridContainer>
					<div className="row titleRow">
						<div className="text-header">{headline}</div>
						<div className="arrows">
							<ButtonUI className="arrow-btn" colorTheme="black" buttonType="arrow" flipped={false} disabled={disableL} callback={() => {this.arrowClick(-1)}}></ButtonUI>
							<ButtonUI className="arrow-btn" colorTheme="black" buttonType="arrow" flipped={true} disabled={disableR} callback={() => {this.arrowClick(+1)}}></ButtonUI>
						</div>
					</div>
					<div className="row slideRow">
						{ this.renderSlides() }
					</div>
				</GridContainer>
			</HorizontalGallerySection>
		)
	}
}
