import React from "react";
import styled from "styled-components";
import { ContentstackImg } from "@riotgames/wwpub-components";
import { breakpoints, colors } from "../../styles/variables";

const ChampCard = styled.div`
  position: relative;
  width: 100%;
  background: ${colors.orange};
  padding: 8px;
  cursor: pointer;
  box-shadow: 8px 8px 0 ${colors.darkOrange};
  transition: box-shadow 0.5s ease-out;

  .champWindow {
    width: 100%;
    padding-top: 260%;
    /* padding-top: 300%; */
    position: relative;
    overflow: hidden;
  }
  .champImage {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .champImage {
    display: flex;
    transform: translate(5%, 0) scale(1.6);
    transition: transform 0.7s ease-out;
  }
  .champImage img {
    display: flex;
    flex: 1;
    margin: auto;
    width: 100%;
    height: auto;
  }
  .text-miniheader {
    color: #fff;
    padding: 24px 0 12px;
    text-align: center;
    text-transform: uppercase;
  }

  &:hover {
    box-shadow: 8px 8px 0 ${colors.lightOrange};

    .champImage {
      transform: translate(5%, 0) scale(1.75);
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .champWindow {
      padding-top: 160%;
    }
    .champImage {
      transform: scale(1.15);
    }
    .text-miniheader {
      padding: 12px 0;
    }
    &:hover {
      .champImage {
        transform: scale(1.25);
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    width: 80vw;
    max-width: 300px;

    .champWindow {
      padding-top: 150%;
    }
    .champImage {
      align-items: center;
      img {
        margin: unset;
      }
    }
  }
`;

interface Props {
  imageSrc: string;
  linkHref: string;
}

export default class ButtonText extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  openURL = (event: React.MouseEvent): void => {
    window.open(this.props.linkHref, "_new");
  };

  render() {
    return (
      <ChampCard onClick={this.openURL}>
        <div className="champWindow">
          <div className="champImage">
            <ContentstackImg image={{ url: this.props.imageSrc }} />
          </div>
        </div>
        <div className="text-miniheader">{this.props.children}</div>
      </ChampCard>
    );
  }
}
