import * as React from "react";
import styled, { css } from "styled-components";
import { breakpoints, colors } from "../styles/variables";
import { device } from "../components/Layout/theme";
import DecoLineTop from "../assets/images/divider/deco-line-top.svg";
import DecoLineBottom from "../assets/images/divider/deco-line-bottom.svg";
import DecoLineTopTablet from "../assets/images/divider/deco-line-top-tablet.svg";
import DecoLineBottomTablet from "../assets/images/divider/deco-line-bottom-tablet.svg";
import DecoLineTopMobile from "../assets/images/divider/deco-line-top-mobile.svg";
import DecoLineBottomMobile from "../assets/images/divider/deco-line-bottom-mobile.svg";

const DecoLineWrapper = styled.div`
  width: 100%;

  svg {
    width: 100%;
  }

  &.top {
    margin-bottom: 24px;
  }
  &.bottom {
    margin-top: 8px;
  }

  .desktop,
  .tablet {
    display: none;
  }

  .mobile {
    display: block;
  }

  @media ${device.mobileXL} {
    &.top {
      margin-bottom: 30px;
    }
    &.bottom {
      margin-top: 30px;
    }

    .desktop,
    .mobile {
      display: none;
    }
    .tablet {
      display: block;
    }
  }

  @media ${device.laptop} {
    .desktop {
      display: block;
    }
    .tablet,
    .mobile {
      display: none;
    }

    &.top {
      margin-bottom: 50px;
    }
    &.bottom {
      margin-top: 56px;
    }
  }
`;

interface Props {
  className?: string;
  position: "top" | "bottom";
}

const DecoLine: React.FC<Props> = ({ className, position }) => {
  return (
    <DecoLineWrapper className={`${className} ${position}`}>
      {position == "top" && (
        <>
          <DecoLineTop className="desktop" />
          <DecoLineTopTablet className="tablet" />
          <DecoLineTopMobile className="mobile" />
        </>
      )}
      {position == "bottom" && (
        <>
          <DecoLineBottom className="desktop" />
          <DecoLineBottomTablet className="tablet" />
          <DecoLineBottomMobile className="mobile" />
        </>
      )}
    </DecoLineWrapper>
  );
};

export default DecoLine;
