import styled from "styled-components";
import { breakpoints, colors, zIndices } from "../../styles/variables";
import { device } from "../../components/Layout/theme";

export const HeroSection = styled.section`
  width: 100%;
  position: relative;
  background: ${colors.darkGreen};

  .backgroundImg {
    position: absolute;
    top: -2%;
    left: -2%;
    width: 104%;
    height: 104%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 350%;

    &:before {
      content: "";
      position: absolute;
      width: 120%;
      aspect-ratio: 1 / 1;
      background: radial-gradient(41.97% 41.97% at 50% 50%, rgba(5, 102, 192, 0.8) 0%, rgba(5, 102, 192, 0) 100%);
      filter: blur(15px);
      top: 320px;
      left: 50%;
      transform: translate(-50%, -25%);
    }
  }

  .char {
    position: absolute;
    background: center center / contain no-repeat;
    pointer-events: none;
  }
  .treeWrapper {
    --width: 1400px;
    width: var(--width);
    top: -10px;
    left: calc(50% - var(--width) / 2);
    z-index: 2;

    -webkit-mask: linear-gradient(black 80%, transparent 85%);
    mask: linear-gradient(black 80%, transparent 85%);
  }
  .mainChar {
    --width: 1300px;
    width: var(--width);
    bottom: -340px;
    left: calc(50% - var(--width) / 2);
  }
  .glow {
    display: none;
    width: 104%;
    bottom: -50px;
    right: 0;
  }

  .centerCol {
    position: relative;
    width: 100%;
    max-width: 1440px;
    padding: 0 24px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: ${colors.white};
    z-index: 5;

    .mainLogo {
      width: 50vw;
      max-width: 240px;
      margin-top: 32px;
      margin-bottom: 16px;
    }
    .infoGroup {
      position: relative;
      text-align: center;
      padding: 1rem 0;
    }
    .available {
      font-size: 16px;
      line-height: 130%;
      margin-bottom: 8px;
    }
    .text-paragraph {
      text-align: center;
      max-width: 600px;
      margin-right: auto;
      margin-left: auto;
      line-height: 1.5em;
      margin-bottom: 18px;
    }

    .platforms {
      display: none;

      /* max-width: 430px; */
      margin: 0 auto 16px;
      svg {
        display: inline-block;
        width: 35px;
        height: 35px;

        position: relative;
      }
      path {
        fill: ${colors.white};
        transition: fill 0.3s ease-out;
      }
    }
  }

  @media ${device.tablet} {
    .backgroundImg {
      background-size: 260%;
    }
    .treeWrapper {
      --width: 2000px;
      width: var(--width);
      top: -20px;
      left: calc(50% - var(--width) / 2);
    }
    .mainChar {
      --width: 2000px;
      width: var(--width);
      bottom: -550px;
      left: calc(50% - var(--width) / 2.05);
    }

    .centerCol {
      padding: 0 70px;

      .mainLogo {
        width: 360px;
        margin-bottom: 50px;
      }

      .platforms {
        max-width: 295px;
        margin-bottom: 16px;
        svg {
          width: 30px;
          height: 30px;
          margin: 0 6px;
        }
      }

      .text-paragraph {
        max-width: 360px;
      }
    }
  }

  @media ${device.laptop} {
    .backgroundImg {
      background-size: 180%;
    }
    .treeWrapper {
      --width: 2000px;
      width: var(--width);
      left: calc(50% - var(--width) / 2);
    }
    .mainChar {
      --width: 180%;
      width: var(--width);
      bottom: calc(var(--width) / 4.8 * -1);
      left: calc(50% - var(--width) / 2.05);
    }

    .centerCol {
      padding: 0 105px;

      .mainLogo {
        width: 35%;
        max-width: 390px;
        margin-top: 67px;
        margin-bottom: 40px;

        [lang="zh-tw"] & {
          max-width: 320px;
        }
      }

      .available {
        margin-bottom: 16px;
      }
      .text-paragraph {
        line-height: 1.5em;
        margin-bottom: 24px;
      }
      .platforms {
        display: block;
        svg {
          width: 35px;
          height: 35px;
          margin: 0 12px;
        }
      }
    }
  }

  /* 1200px */
  @media ${device.laptopM} {
    .treeWrapper {
      --width: 2600px;
      width: var(--width);
      top: -50px;
      left: calc(50% - var(--width) / 2);
    }
    .glow {
      display: block;
      width: 100%;
      bottom: -6%;
      right: -1%;
    }
    .mainChar {
      --width: 160%;
      width: var(--width);
      bottom: calc(var(--width) / 12 * -1);
      left: calc(50% - var(--width) / 2.05);
    }
  }

  @media (min-width: 1500px) {
    .backgroundImg {
      background-size: 150%;
    }
    .treeWrapper {
      width: 103%;
      height: 103%;
      object-fit: cover;
      left: -1.5%;
      top: -2%;
    }
    .mainChar {
      width: 103%;
      height: 103%;
      object-fit: cover;
      left: -1.5%;
      bottom: calc(-50px + -2.5%);
    }
  }

  @media (min-width: 1650px) {
    .backgroundImg {
      background-size: 130%;
    }
    .mainChar {
      bottom: -2.5%;
    }
  }

  @media (min-width: 2000px) {
    .backgroundImg {
      background-size: 105%;
    }
  }

  @media (orientation: portrait) and (max-height: 700px) {
    .centerCol .platforms {
      display: none;
    }
  }
`;
