// Removes decimals to optimize SVG paths
export function decimateSVG(path: string): string {
	return path.replace(/[0-9]*\.[0-9]*/g, function(x: string): string {
		// return (+x).toFixed(1);
		// return Math.round(+x);
		return (Math.round(+x * 10) / 10).toString();
	});
}

// JS modulo fix
export function mod( n: number, m: number ): number {
	return ( ( n % m ) + m ) % m;
}

// Restricts value between [min, max]
export function clamp( value: number, min: number, max: number ): number {
	return Math.max( min, Math.min( max, value ) );
}