import styled from "styled-components";
// import { size } from "../../themes/Base/theme";

/*  Whatever theme you pass into your website's ThemeProvider
 *  also gets passed to this component. That theme can have an
 *  email_form_settings object to set a custom email theme.
 *  If the theme does not have an email_form_settings object,
 *  then we fall back to the theme you imported from the shared library.
 *  If you did not import any themes from the shared library,
 *  then we fall back to the component's default values set here.
 */

export const StyledButton = styled.button`
  font-family: ${(props) =>
    props.theme?.email_form_settings?.font_family || props.theme?.email_form_font_family || "FF Mark W05"};
  font-size: 13px;
  font-weight: 700;
  color: ${(props) => props.theme?.email_form_settings?.font_color || props.theme?.email_form_font_color || "#F9F9F9"};
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background: ${(props) =>
    props.theme?.email_form_settings?.button_color ||
    props.theme?.primary_button_settings?.background_color ||
    props.theme?.email_form_button_color ||
    "rgba(209, 54, 57, 1)"};
  border-radius: 6.4px;
  border: 2px solid transparent;
  padding: 14px 52px;
  margin: 40px auto 0 0;
  opacity: 1;

  &:hover {
    background: ${(props) =>
      props.theme?.email_form_settings?.button_color ||
      props.theme?.primary_button_settings?.background_color ||
      props.theme?.email_form_button_color ||
      "rgba(209, 54, 57, 1)"};
    opacity: 0.8;
  }

  &:active {
    background: ${(props) =>
      props.theme?.email_form_settings?.button_color ||
      props.theme?.primary_button_settings?.background_color ||
      props.theme?.email_form_button_color ||
      "rgba(209, 54, 57, 1)"};
    color: ${(props) =>
      props.theme?.email_form_settings?.font_color || props.theme?.email_form_font_color || "#F9F9F9"};
  }

  &:disabled {
    color: #525252;
    background: transparent;
    border: 2px solid #525252;
  }
`;

export const StyledField = styled.div`
  position: relative;
  margin: 0 0 30px;
  padding: 0;

  label {
    font-family: ${(props) =>
      props.theme?.email_form_settings?.font_family || props.theme?.email_form_font_family || "FF Mark W05"};
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #b8b8b8;
    position: absolute;
    top: 32%;
    left: 16px;
  }

  label.floating {
    font-family: ${(props) =>
      props.theme?.email_form_settings?.font_family || props.theme?.email_form_font_family || "FF Mark W05"};
    font-size: 9.1px;
    font-weight: 700;
    line-height: 100%;
    color: #b8b8b8;
    align-items: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    position: absolute;
    left: 12px;
    top: 9px;
    min-width: 244px;
    width: 100%;
  }

  input[type="text"] {
    border-radius: 4px;
    border: 1px solid #262626;
    box-sizing: border-box;
    box-shadow: none;
    min-width: 200px;
    width: 100%;
    height: 52px;
    padding: 18px 11px 8px;
    background: rgba(138, 138, 138, 0.15);
    font-family: ${(props) =>
      props.theme?.email_form_settings?.font_family || props.theme?.email_form_font_family || "FF Mark W05"};
    font-size: 16px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.06em;
    display: flex;
    align-items: center;
    color: ${(props) =>
      props.theme?.email_form_settings?.font_color || props.theme?.email_form_font_color || "#F9F9F9"};

    ::placeholder {
      font-family: ${(props) =>
        props.theme?.email_form_settings?.font_family || props.theme?.email_form_font_family || "FF Mark W05"};
      font-size: 10.24px;
      line-height: 100%;
      display: flex;
      align-items: center;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: #b8b8b8;
    }
  }

  input[type="text"].error {
    background: rgba(190, 41, 204, 0.1);
    border: 2px solid rgba(190, 41, 204, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const StyledForm = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 100px;
  flex-basis: auto;
  margin: auto;
  background-color: transparent;
  background-image: url(${(props) =>
    props.theme?.email_form_settings?.background_image?.url ||
    props.theme?.email_form_background_image ||
    "https://assetcdn.rgpub.io/public/live/forge-archive-hosting/bandletale/79935724723034f7c7f99294b4ce57af.jpg"});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 425px) {
    background-image: none;
    padding: 0;
  }

  .post-signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: ${(props) =>
      props.theme?.email_form_settings?.font_family || props.theme?.email_form_font_family || "FF Mark W05"};
    line-height: 120%;
    background: ${(props) =>
      props.theme?.email_form_settings?.background_color ||
      props.theme?.background_color ||
      props.theme?.email_form_background_color ||
      "#292727"};
    height: 443px;
    width: 100%;
    min-width: 370px;
    max-width: 440px;
    min-height: 330px;
    padding: 48px;

    @media (min-width: 320px) {
      max-width: 375px;
      width: 100%;
      height: 100%;
      margin: 0;
      border: none;
      padding: 48px;
    }

    @media (min-width: 1024px) {
      max-width: 462px;
      width: 100%;
    }

    @media (min-width: 1920px) {
      max-width: 600px;
      width: 100%;
    }

    .post-signup-content {
      text-align: center;
      font-weight: 700;

      h1 {
        color: ${(props) =>
          props.theme?.email_form_settings?.font_color || props.theme?.email_form_font_color || "#F9F9F9"};
        font-size: 25px;
      }

      p {
        color: ${(props) =>
          props.theme?.email_form_settings?.font_color || props.theme?.email_form_font_color || "#F9F9F9"};
        font-size: 12px;
      }
    }
  }

  .signup {
    background: ${(props) =>
      props.theme?.email_form_settings?.background_color ||
      props.theme?.background_color ||
      props.theme?.email_form_background_color ||
      "#292727"};
    border: 1px solid #262626;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 38px;
    width: 100%;
    min-width: 370px;
    max-width: 440px;
    min-height: 330px;
    margin: 80px auto;

    @media (min-width: 320px) {
      max-width: 375px;
      width: 100%;
      height: 100%;
      margin: 0;
      border: none;
      padding: 48px;
    }

    @media (min-width: 1024px) {
      max-width: 462px;
      width: 100%;
    }

    @media (min-width: 1920px) {
      max-width: 600px;
      width: 100%;
    }

    h1 {
      color: ${(props) =>
        props.theme?.email_form_settings?.font_color || props.theme?.email_form_font_color || "#F9F9F9"};
      font-family: ${(props) =>
        props.theme?.email_form_settings?.font_family || props.theme?.email_form_font_family || "FF Mark W05"};
      font-size: 25px;
      line-height: 120%;
      text-align: center;
      letter-spacing: -0.02em;
      margin: 0px 0px 10px;
      text-transform: capitalize;
    }

    .subheader-dark {
      text-align: center;
      margin: 0 0 12px;
      color: ${(props) =>
        props.theme?.email_form_settings?.font_color || props.theme?.email_form_font_color || "#F9F9F9"};
      font-family: ${(props) =>
        props.theme?.email_form_settings?.font_family || props.theme?.email_form_font_family || "FF Mark W05"};
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: -0.01em;
      padding-bottom: 24px;

      @media (max-width: 600px) {
        padding: 0 0 20px;
      }
    }
  }
`;
