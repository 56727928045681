import React from "react";
import parse from "react-html-parser";
import { graphql } from "gatsby";
import { navigate } from "@reach/router";
import { StyledDiv } from "./styles";

export const homeFragment = graphql`
  fragment TopBannerFragment on Contentstack_bandle_tale_home_page {
    topBanner: top_banner {
      message
      url
    }
  }
`

export interface TopBannerData {
  message: string
  url: string;
}

interface Props {
  data: TopBannerData
}


const TopBanner: React.FC<Props> = ({ data }) => {
  const {message, url} = data;
  const bannerClass = url.length > 0 ? "link" : "";

  const handleClick = () => {
    if (url.length > 0) {
      navigate(url);
    }
  }

  return (<StyledDiv onClick={handleClick} className={bannerClass}>{parse(message)}</StyledDiv>)
}

export default TopBanner;
